.ant-input:focus,
.ant-input:hover,
.ant-input-focused {
  border-color: $color-green;
  box-shadow: none !important;
}
.ant-input-number:focus,
.ant-input-number:hover,
.ant-input-number-focused {
  border-color: $color-green;
  box-shadow: none !important;
}
.ant-input[disabled] {
  cursor: initial !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:hover {
  border-color: $color-green;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: $color-green;
  box-shadow: none !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: $color-green;
  box-shadow: none !important;
}

.ant-input-number-affix-wrapper {
  padding: 0px 22px !important;
  padding-left: 10px !important;
}

.ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-focused {
  border-color: $color-green;
  box-shadow: none !important;
}

.input-prix-unitaire .ant-input-number-handler-wrap,
.input-prix-tonne .ant-input-number-handler-wrap,
.input-forfait-max .ant-input-number-handler-wrap
{
  display: none;
}

.ant-form {
    .ant-form-item-has-error {
        .google-auto-complete-input {
          border-color: $color-danger !important;
        }
    }
}

.google-auto-complete-input {
  box-sizing:border-box;
  margin:0;
  padding:4px 11px;
  color:rgba(0, 0, 0, 0.88);
  font-size:14px;
  line-height:1.5714285714285714;
  list-style:none;
  font-family:-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  position:relative;
  display:inline-block;
  width:100%;
  min-width:0;
  background-color:#ffffff;
  background-image:none;
  border-width:1px;
  border-style:solid;
  border-color:#d9d9d9;
  border-radius:6px;
  transition:all 0.2s;
}
.google-auto-complete-input::-moz-placeholder {
  opacity:1;
}
.google-auto-complete-input::placeholder {
  color:rgba(0, 0, 0, 0.25);
  user-select:none;
}
.google-auto-complete-input:placeholder-shown {
  text-overflow:ellipsis;
}
.google-auto-complete-input:hover {
  border-color: $color-green;
}
.google-auto-complete-input:focus,
.google-auto-complete-input-focused {
  border-color: $color-green;
  box-shadow: none !important;
  outline:0;
}
.input-prix-unitaire input{
  text-align: right !important;
}
.input-prix-unitaire{
  background-color: #ffffff !important;
  width: 105px;
}
.input-prix-tonne,
.input-forfait-max
{
  background-color: #ffffff !important;
}
.input-line-under{
  width: 120px;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.45);
}
.remise-hover-input-and-select{
  display: inline-flex;
}
.remise-hover-input-and-select input {
  width: 70px !important;
  height: 32px;
  border-radius: 6px 0px 0px 6px;
  border-right: none;
}
.remise-hover-input-and-select .text-right {
  width: 50px;
}
.remise-hover-input-and-select:hover input,
.remise-hover-input-and-select:hover .ant-select-selector,
.remise-hover-input-and-select:focus-within input,
.remise-hover-input-and-select:focus-within .ant-select-selector{
  border-color: $color-green !important;
}

.ant-input-number-affix-wrapper-status-error:hover,
.ant-input-number-affix-wrapper-status-error:focus-within {
  .ant-input-number-suffix{
    color: rgba(0, 0, 0, 0.88) !important;
  }
}
.ant-input-number-affix-wrapper:hover,
.ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper:focus-within {
  border-color: $color-green !important;
}