// RESPONSIVE BREAKPOINTS
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html,
body {
  box-sizing: border-box;
  font-family: 'Roboto';
  font-size: 14px;

  body {
    font-size: 14px;
    color: #fff;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  abbr[title],
  abbr[data-original-title] {
    text-decoration: none;
  }

  p,
  a,
  div,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  figure {
    margin: 0;
    padding: 0;
  }

  .default-image,
  .responsive-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .full-width {
    width: 100%;
  }

  .full-height {
    min-height: 100%;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .hover-cursor-pointer:hover {
    cursor: pointer;
  }
}