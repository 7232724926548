.order-payment-info-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px; /* Height of the container */
  margin-bottom: 20px;
}

.line {
  width: 100%;
  height: 1px; /* Thickness of the line */
  background-color: rgb(204, 204, 204);;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.centered-div {
  z-index: 1; /* Ensure it appears above the line */
  display: inline-block;
  padding: 9px 12px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: all;
}

.centered-div-popup {
  z-index: 1; /* Ensure it appears above the line */
  display: inline-block;
  padding: 9px 12px;
  background: #ffffff;
  pointer-events: all;
}