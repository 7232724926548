.ant-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px !important;
    gap: 16px;
    width: auto !important;
    height: auto !important;
    background: $bg-white;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 8px;

    .ant-modal-header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 24px 8px;
        gap: 10px;
        height: 56px;
        width: 100%;
        background: rgba(166, 200, 77, 0.5) !important;
        margin: 0;

        .ant-modal-title {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500 !important;
            font-size: 18px;
            line-height: 32px;
            display: flex;
            align-items: center;
            text-align: center;
            color: $text-dark !important;
        }
    }

    .ant-modal-body {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 24px 12px;
        width: 100%;
        height: 100%;
    }

    &__title-of-product-label {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 0px 0px;
        gap: 4px;
        width: 326px;
        height: 30px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.88);
    }

    &__title-of-product-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 12px;
        gap: 10px;
        width: 326px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 6px;

        &:hover {
            border-color: $color-green !important;
        }

        &:focus-within {
            border-color: $color-green !important;
        }
    }

    .ant-modal-footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 0px 24px 24px;
        gap: 8px;
        width: 100%;
        height: 56px;
        margin: 0;
    }

    &__add-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 16px;
        gap: 8px;
        min-width: 77px;
        height: 32px;
        background: $text-green;
        border: 1px solid $text-green;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.02);
        border-radius: 8px;

        &:hover {
            background: $text-green !important;
        }
    }

    &__cancel-btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 16px;
        gap: 8px;
        // width: 80px;
        height: 32px;
        background: #1D2123;
        color: $text-white;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.02);
        border-radius: 8px;
        border-color: $color-dark !important;

        &:hover {
            background: $color-dark !important;
            color: $text-white !important;
            border-color: $color-dark !important;
        }
    }
}

.ant-modal-close-icon svg {
    width: 14px !important;
    height: 14px !important;
}

.ant-form-item-modal-vertical {
    margin-bottom: 0;
    .ant-form-item-row {
        flex-direction: column;
        align-items: flex-start;
    }

    .ant-form-item-control {
        width: 100%;
        flex: unset;
    }
}
.ant-form-item-modal-horizontal {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-bottom: 0;
    margin-top: 16px;
    .ant-form-item-control {
        width: 400px;
    }
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: $color-green !important;
    background-color: $color-green !important;
}
.modal-demander-un-prix {
    width: 700px !important;
}

.button-create-cancel-search-client {
    width: 100%;
    margin-top: 20px;
 }