.ant-tag {
    text-transform: unset;

    &.color_status_new {
        color: $color-status-new;
        border-color: $color-status-new-border;
        background: $color-status-new-background;
    }

    &.color_status_draft {
        color: $color-status-draft;
        border-color: $color-status-draft-border;
        background: $color-status-draft-background;
    }

    &.color_status_sent {
        color: $color-status-sent;
        border-color: $color-status-sent-border;
        background: $color-status-sent-background;
    }

    &.color_status_accepted {
        color: $color-status-accepted;
        border-color: $color-status-accepted-border;
        background: $color-status-accepted-background;
    }
} 