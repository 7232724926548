.text {
    &-primary {
        color: $color-primary;
    }

    &-second {
        color: $color-secondary;
    }

    &-white {
        color: $color-white;
    }

    &-grey {
        color: $color-grey;
    }

    &-dark {
        color: $color-dark;
    }
}

.font-italic {
    font-style: italic
}

.font-weight {
    &-thin {
        font-weight: 100;
    }

    &-light {
        font-weight: 300;
    }

    &-regular {
        font-weight: 400;
    }

    &-medium {
        font-weight: 500;
    }

    &-semibold {
        font-weight: 600;
    }

    &-bold {
        font-weight: 700;
    }

    &-black {
        font-weight: 900
    }
}

.text-decoration {

    &-line-through {
        text-decoration: line-through;
    }

    &-none {
        text-decoration: none;
    }

    &-overline {
        text-decoration: overline;
    }

    &-underline {
        text-decoration: underline;

    }
}

.text-pre {

    white-space: pre;

    &-line {
        white-space: pre-line;
    }

    &-wrap {
        white-space: pre-wrap;
    }
}

.text {
    &-left {
        text-align: left;
    }

    &-right {
        text-align: right;
    }

    &-center {
        text-align: center;
    }

    &-justify {
        text-align: justify;
    }

    &-start {
        text-align: start;
    }

    &-end {
        text-align: end;
    }

    &-wrap {
        white-space: normal;
    }

    &-no-wrap {
        white-space: nowrap;
    }

    &-break {
        overflow-wrap: break-word;
        word-break: break-word;
    }

    &-truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &-none {
        text-transform: none;
    }

    &-capitalize {
        text-transform: capitalize;
    }

    &-lowercase {
        text-transform: lowercase;
    }

    &-uppercase {
        text-transform: uppercase;
    }

    &-ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}