.ant-picker:hover,
.ant-picker-focused {
  border-color: $color-green;
  box-shadow: none !important;
}
.ant-picker-disabled, .ant-picker-disabled * {
  cursor: initial !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid $color-green;
}
.ant-picker-dropdown .ant-picker-today-btn,
.ant-picker-dropdown .ant-picker-today-btn:hover,
.ant-picker-dropdown .ant-picker-today-btn:active {
  color: $color-status-accepted;
}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color:#fff;
  background:$color-primary;
}

.ant-picker-dropdown .ant-picker-header-view button:hover {
  color: $color-status-accepted;
}
.ant-picker-cell .ant-picker-cell-inner,
.ant-picker-date-panel .ant-picker-content th,
.ant-picker-header button {
  text-transform: capitalize !important; 
}