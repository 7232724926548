.main-title {
    display: flex;
    align-items: center;
    font-size: 24px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 400 !important;

    &__child {
        font-weight: 700 !important;
        padding-left: 6px;
    }
}