.ant-select-item-option-active {
    background-color: $hover-item-color !important;
}

.ant-select-item-option-selected {
    background-color: $press-item-color !important;
}

// .ant-select-selection-item {
//     padding-inline-end: 70px !important;
// }
.ant-select-disabled * {
    cursor: initial !important;
}

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector,
.ant-select-selector:hover,
.ant-select-selector:focus-within {
    border-color: $color-green !important;
    box-shadow: none !important;
}

.ant-dropdown-menu .ant-dropdown-menu-item {
    &:hover {
        background-color: rgba(0, 0, 0, 0.25) !important;
    }
}

.ant-select-selection-overflow-item-rest {
    .ant-select-selection-item {
        align-items: center !important;
        height: 22px !important;
        background: rgba(0, 0, 0, 0.04) !important;
        border: 1px solid rgba(0, 0, 0, 0.06) !important;
    }
}

#select-all {
    background-color: unset !important;
}

#clear-all {
    background-color: unset !important;
}
.text-right .ant-select-selector {
    padding-left: 0 !important;
    text-align: left;
    border-radius: 0 6px 6px 0 !important;
    border-left: none !important;
}