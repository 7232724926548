.ant-checkbox.ant-checkbox-checked {
  .ant-checkbox-inner,
  .ant-checkbox-inner:hover {
    background-color: $color-green !important;
    border-color: $color-green !important;
  }
}
.ant-checkbox-checked:after {
  // background-color: $color-green !important;
  border-color: $color-green !important;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner {
  border-color: $color-green !important;
}