@import 'sidebar';


.layout {
  background: $color-white;
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  overflow: hidden;
}

.container {
  background: $color-white;
}

.main-content {
  flex: 1 !important;
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 54px;
  color: $text-dark;
  margin-left: 100px;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: $bg-green !important;
}
