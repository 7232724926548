$res_320 : "only screen and (min-width: 320px) and (max-width: 374px)";
$res_375 : "only screen and (min-width: 375px) and (max-width: 424px)";
$res_425 : "only screen and (min-width: 425px) and (max-width: 767px)";
$res_768 : "only screen and (min-width: 768px) and (max-width: 991px)";
$res_992 : "only screen and (min-width: 992px) and (max-width: 1023px)";
$res_1024 : "only screen and (min-width: 1024px) and (max-width: 1999px)";
$res_1200 : "only screen and (min-width: 1200px) and (max-width: 1299px)";
$res_1300 : "only screen and (min-width: 1200px) and (max-width: 1399px)";
$res_1400 : "only screen and (min-width: 1400px)";
 
@mixin responsive_320() {
    @media #{$res_320} {
        @content;
    }
}
@mixin responsive_375() {
    @media #{$res_375} {
        @content;
    }
}
@mixin responsive_425() {
    @media #{$res_425} {
        @content;
    }
}
@mixin responsive_768() {
    @media #{$res_768} {
        @content;
    }
}
@mixin responsive_992() {
    @media #{$res_992} {
        @content;
    }
}
@mixin responsive_1024() {
    @media #{$res_1024} {
        @content;
    }
}
@mixin responsive_1200() {
    @media #{$res_1200} {
        @content;
    }
}
@mixin responsive_1300() {
    @media #{$res_1300} {
        @content;
    }
}
@mixin responsive_1400() {
    @media #{$res_1400} {
        @content;
    }
}
.ant-input-affix-wrapper-disabled, .ant-input-affix-wrapper-disabled *, 
.ant-input-number-affix-wrapper-disabled, .ant-input-number-affix-wrapper-disabled *,
.ant-input-number-disabled .ant-input-number-input{
    cursor: initial !important;
}
.ant-btn[disabled] {
    cursor: initial !important;
}
.create-estimate-form {
    .ant-form-item-explain-error {
        display: none;
    }
    .ant-form-item-has-error {
        .google-auto-complete-input {
          border-color: #ff4d4f;
        }
    }
}
.update-estimate-form {
    .ant-form-item-explain-error {
        display: none;
    }
    .ant-form-item-has-error {
        .google-auto-complete-input {
          border-color: #ff4d4f;
        }
    }
    .ant-input-disabled,
    .ant-input[disabled],
    .ant-select-disabled .ant-select-selector,
    .ant-picker-disabled {
        background-color: #ffffff !important;
        color: rgba(0, 0, 0, 0.88) !important;
        input {
            color: rgba(0, 0, 0, 0.88) !important;
        }
    }
}
.client-chantier {
    .ant-col {
        max-width: 100%;
    }

    .ant-form-item {
        display: flex;
        align-content: end;
        justify-content: end;
        margin-bottom: 0;
        margin-top: 16px;
    }

    .ant-form-item .ant-form-item-label>label {
        text-align: right;
    }

    .ant-form-item-row {
        min-width: max-content;
    }

    .ant-form-item-control {
        width: 330px !important;
    }
    @include responsive_1024{
        .ant-form-item-control {
            max-width: 160px !important;
        }
    }
    @include responsive_1200{
        .ant-form-item-control {
            max-width: 180px !important;
        }
    } 
    @include responsive_1300{
        .ant-form-item-control {
            max-width: 270px !important;
        }
    }
    @include responsive_1400{
        .ant-form-item-control {
            max-width: 300px !important;
        }
    }

    .ant-input-disabled,
    .ant-input[disabled] {
        background-color: #ffffff;
        color: rgba(0, 0, 0, 0.88);
    }

    .ecodrop-section {
        &__left-column {
            padding-left: 60px !important;
        }

        &__right-column {
            padding-left: 110px !important;
        }
    }
    .ecodrop-section__left-column {
        .ant-form-item {
            width: 100%;
            padding-right: 40px;
            .ant-form-item-label {
                min-width: 150px;
            }
        }
    }
    .form-item-with-icon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // width: 100%;
        .ant-form-item {
            width: 100%;
            padding-right: 0px;
        }
        &_icon {
            display: flex;
            padding-top: 16px;
            padding-left: 16px;
            width: 40px;
            > span+span {
                padding-left: 10px;
            }
        }
    }
}
.prodct-cell {
    &__display-right {
        display: flex;
        justify-content: right;
    }
}

.create-contact-form {
    width: 100%;
    padding: 16px 56px 0;
    .ant-form-item-explain-error {
        display: none;
    }
}
.demander-un-prix-form {
    .ant-form-item-explain-error {
        display: none;
    }
}

.logistique {
    &__alert {
        background-color: $press-item-color;
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 8px;
        line-height: 14px !important;
        padding: 12px 20px;
        width: 100%;
        margin-bottom: 20px;
        border-radius: 8px !important;
    }

    &__card {
        background-color: #ffffff;
        height: 100%;
        display: block;
        border-radius: 8px;
        min-height: 262px;
        border: 1px solid $border-dark;
        .ant-card-head {
            border-bottom: 1px solid $border-dark;
            padding: 0px 16px;
        }
        .ant-card-head-title {
            font-size: 16px;
            color: $text-green;
            font-weight: 400;
            line-height: 22px;
        }
    }

    &__document-textarea {
        border-radius: 8px;
        padding: 5px 0px !important;
        border: 1px solid $border-dark;
        textarea {
            min-height: 176px !important;
        }
    }
}

.quotation {
    &__card {
        background-color: $press-item-color;
        height: 100%;
        display: block;
        border-radius: 15px;
    }

    &__button-block {
        margin-bottom: 20px;
    }

    &__subtotal-card {
        background-color: $bg-white;
        height: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        border: 1px solid $border-dark;
    }

    &__subtotal-block {
        margin-right: 72px;
        margin-left: auto;
        margin-bottom: 20px;
        .ant-card-body {
            padding: 0;
        }
        // .custom-form-item {
        //     margin-bottom: 4px !important;
        // }
    }

    &__subtotal-left-col {
        width: 50%;
        background-color: $press-item-color;
        align-items: left;
        text-align: left;
        padding: 10px;
    }

    &__subtotal-right-col {
        width: 50%;
        background-color: $bg-white;
        text-align: right;
        padding: 10px 18px 10px 10px;
    }

    &__total-text {
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
    }

    .ant-table-content>table {
        border: 0.5px solid rgba(187, 187, 187, 0.5);
    }

    .ant-table-tbody {
        vertical-align: top;
    }

    .ant-table-wrapper .ant-table-thead>tr>th,
    .ant-table-wrapper .ant-table-thead>tr>td {
        background: $press-item-color;
        border-color: red;
        border: none;
    }

    .ant-table-wrapper .ant-table-thead .ant-table-cell-fix-right {
        background: $press-item-color !important;
        border-color: red;
        border: none;
    }

    .ant-table-wrapper table,
    .ant-table-wrapper .ant-table-container table>thead>tr:first-child> :first-child,
    .ant-table-wrapper .ant-table-container table>thead>tr:first-child> :last-child {
        border-radius: 0 !important;
    }

    .ant-form-item-explain-error {
        display: none;
    }
    .ant-table-thead .ant-table-cell {
        font-family: 'Roboto-Bold';
        font-weight: 700 !important;
        font-size: 14px;
        line-height: 22px;
    }

    .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container {

        .ant-table-content>table>thead>tr>th,
        .ant-table-header>table>thead>tr>th,
        .ant-table-body>table>thead>tr>th,
        .ant-table-summary>table>thead>tr>th,
        .ant-table-content>table>tbody>tr>th,
        .ant-table-header>table>tbody>tr>th,
        .ant-table-body>table>tbody>tr>th,
        .ant-table-summary>table>tbody>tr>th,
        .ant-table-content>table>tfoot>tr>th,
        .ant-table-header>table>tfoot>tr>th,
        .ant-table-body>table>tfoot>tr>th,
        .ant-table-summary>table>tfoot>tr>th {
            border-inline-end: none;

            &:before {
                border-inline-end: 1px solid rgba(0, 0, 0, 0.15);
            }
        }

        .quotation-title-line {
            background: #FAFAFA;

            .ant-table-cell {
                border-inline-end: none;
                position: sticky;
                width: 100%;
                left: 0 !important;

                input {
                    position: sticky;
                    width: 95% !important;
                    z-index: 99;
                }
            }

            .ant-table-cell {
                background: #FAFAFA;
                border-left: 0.5px solid rgba(0, 0, 0, 0.06);
            }

        }
    }

    .ant-form-item-vertical {
        .ant-form-item-row {
            flex-direction: column;
            align-items: flex-start;
        }

        .ant-form-item-control {
            width: 100%;
            flex: unset;
        }
    }

    .ant-switch {
        &.ant-switch-checked {
            background-color: $bg-green !important;
            .ant-switch-inner {
                background-color: $bg-green;
            }
        }
    }
    
    table .ant-table-cell:nth-child(2) .ant-space {
        width: 100% !important;
    }

    table .ant-table-cell:nth-child(2) .ant-space .ant-picker {
        width: 100% !important;
    }
}

.actions-block {
    display: flex;
    flex-direction: row;
    gap: 8px 16px;
    justify-content: center;
}

.create-estimate-form .ant-table-content .textareaProduct{ 
    resize: none !important;   
    max-height: 480px !important;
    width: 100% !important; 
    padding: 10px !important;
}

.update-estimate-form .ant-table-content .textareaProduct{ 
    resize: none !important;   
    max-height: 480px !important;
    width: 100% !important; 
    padding: 10px !important; 
}
.ant-form-item-hidden,
.ant-form-item-hidden.ant-row {
  display:none !important;
}

.ant-tooltip-inner {
    color: rgba(0, 0, 0, 0.88) !important;
    background-color: $bg-green !important;
}
.ant-tooltip-arrow::before{
    background-color: $bg-green !important;
}
label .ant-form-item-tooltip{
    cursor: not-allowed !important;
}

/* tile uploaded pictures */
.upload-list-inline .ant-upload-list-item {
    display: none !important;
}
.upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
    float: right;
}
.list-file-upload {
    display: flex;
    float: right;
}
.bnt-upload-file {
    width: 27px !important;
    height: 27px !important;
    margin-top: 2px;
}
