.ant-space {
    height: auto !important;
    border-radius: 0 !important;

    .ant-card-head {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px 16px;
        gap: 8px;
        width: 100%;
        line-height: 14px !important;
        background: rgba(166, 200, 77, 0.5);
        border-bottom: 1px solid $bg-green;
        border-radius: unset !important;
    }

    .ant-card-head-title {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.88);
    }
}
.space-remise{
    gap: 0 !important;
}
.space-prix-unitaire {
    align-items: center !important;
}