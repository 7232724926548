.main-sidebar {
    position: fixed !important;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;
    width: 230px !important;
    max-width: 230px;
    background: $bg-dark !important;

    >.ant-layout-sider-children {
        display: flex;
        flex-direction: column;
    }

    &__main-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 35px;
        padding-top: 62px;
        padding-bottom: 45px;
        background: $bg-dark !important;
    }

    .ant-card-body {
        padding: 0;
    }

    &__logo-image {
        max-width: 193px;
        max-height: 95px;
        object-fit: cover;
        object-position: center;
    }

    &__main-menu {
        background: $bg-dark !important;
    }

    &__item {
        line-height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 0px 24px 0px 48px;
        gap: 8px;
        // box-shadow: 0px 1px 2px -2px rgba(0, 0, 0, 0.16), 0px 5px 12px 4px rgba(0, 0, 0, 0.09);
        // filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.12));
        border-radius: 8px;
        color: $text-white;
    }

    &__icon {
        margin-right: 10px;
        margin-left: 49.33px;
    }

    &__dropdown-icon {
        margin-right: 10px;
        margin-left: 49.33px;
    }

    &__arrow-icon {
        margin-left: 20px;
    }

    .ant-menu-title-content {
        margin-left: 0 !important;
    }

    &__navigation {
        color: $text-white;
        font-weight: 400;
        line-height: 22px;
    }

    &__sub-navigation {
        margin-left: 76px;
        color: $text-white;
        font-weight: 400;
        line-height: 22px;
    }

    &__product-item {
        background: $bg-dark !important;
    }

    &__region-item {
        background: $bg-dark !important;
    }

    &__estimate-item {
        background: $bg-dark !important;
    }

    .ant-menu-item-selected &__navigation {
        font-weight: 700;
    }

    .ant-menu-item-selected {
        box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
        filter: none;
        background: $bg-green !important;
    }

    .ant-menu-sub-item-selected {
        box-shadow: none !important;
        color: $text-green !important;
        background: none !important;
    }

    .bottom-sidebar {
        display: flex;
        margin-top: auto;
        flex-direction: column;
        align-items: center;
        padding-bottom: 56px;

        .logout-link>span {
            font-style: italic;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            text-decoration-line: underline;
            color: $text-green;
        }

        .copyright-text {
            padding-top: 8px;
            font-weight: 600;
            font-size: 8px;
            line-height: 13px;
            color: $text-white;
        }
    }

    &__submenu {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease;
    }

    &__submenu-open {
        max-height: 500px;
        transition: max-height 0.5s ease;
    }

    &__arrow-icon {
        display: inline-block;
        transition: transform 0.5s ease;
    }

    &__arrow-icon-up {
        transition: transform 0.5s ease;
    }

    &__arrow-icon-down {
        transition: transform 0.5s ease;
    }
}