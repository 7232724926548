.transfer-component {
        .ant-transfer-list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            width: 100% !important;
            height: 450px !important;
            background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 6px;

            .ant-transfer-list-body-with-search {
                width: 100% !important;
            }

            .ant-transfer-list-body-search-wrapper {
                line-height: 32px !important;

                &:hover {
                    border-color: $color-green !important;
                }

                &:focus-within {
                    border-color: $color-green !important;
                }
            }
        }

        .ant-btn-primary {
            width: 24px;
            height: 24px;
            background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.15);
            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.02);
            border-radius: 4px;
            color: #434343;

            &:enabled {
                background-color: $bg-green !important;
                color: $text-white !important;
                border: 1px solid $text-white;
            }
        }

        .ant-checkbox .ant-checkbox-inner {
            background-color: $bg-white !important;
            border: 1px solid rgba(0, 0, 0, 0.15) !important;
            color: rgba(0, 0, 0, 0.15) !important;

            &::after {
                border: 2px solid #434343 !important;
                border-left: none !important;
                border-top: none !important;
            }
        }

        .ant-checkbox-indeterminate .ant-checkbox-inner {
            &::after {
                display: none !important;
            }
        }

        .ant-transfer-list-header {
            display: inline-block !important;
            text-align: center !important;
            width: 100% !important;
        }

        .ant-transfer-list-content-item-checked {
            background-color: rgba(166, 200, 77, 0.5) !important;
        }
    }
