.btn {
    &-primary {
        border-color: $color-bnt-primary !important;
        background: $color-bnt-primary !important;
        color: $color-white !important;

        &:hover,
        &:active,
        &:focus {
            border-color: $color-bnt-primary !important;
            background: $color-bnt-primary !important;
            color: $color-white !important;

        }

        &.ant-btn-text,
        &.ant-btn-text:hover,
        &.ant-btn-text:focus {
            color: $color-bnt-primary;
            border-color: transparent;
            background: transparent;
        }

        &.ant-btn-link,
        &.ant-btn-link:hover,
        &.ant-btn-link:focus {
            color: $color-bnt-primary;
            border-color: transparent;
            background: transparent;
            box-shadow: none;
        }
    }

    &-red {
        border-color: $color-bnt-red !important;
        background: $color-bnt-red !important;
        color: $color-white !important;

        &:hover,
        &:active,
        &:focus {
            border-color: $color-bnt-red !important;
            background: $color-bnt-red !important;
            color: $color-white !important;

        }

        &.ant-btn-text,
        &.ant-btn-text:hover,
        &.ant-btn-text:focus {
            color: $color-bnt-red;
            border-color: transparent;
            background: transparent;
        }

        &.ant-btn-link,
        &.ant-btn-link:hover,
        &.ant-btn-link:focus {
            color: $color-bnt-red;
            border-color: transparent;
            background: transparent;
            box-shadow: none;
        }
    }

    &-dangerous,
    &-danger {
        border-color: $color-danger !important;
        background: $color-danger !important;
        color: $color-white !important;

        &:hover,
        &:active,
        &:focus {
            border-color: $color-danger !important;
            background: $color-danger !important;
            color: $color-white !important;
        }

        &.ant-btn-text,
        &.ant-btn-text:hover,
        &.ant-btn-text:focus {
            color: $color-danger;
            border-color: transparent;
            background: transparent;
        }

        &.ant-btn-link,
        &.ant-btn-link:hover,
        &.ant-btn-link:focus {
            color: $color-danger;
            border-color: transparent;
            background: transparent;
            box-shadow: none;
        }
    }

    &-warning {
        border-color: $color-warning !important;
        background: $color-warning !important;
        color: $color-white !important;

        &:hover,
        &:active,
        &:focus {
            border-color: $color-warning !important;
            background: $color-warning !important;
            color: $color-white !important;

        }

        &.ant-btn-text,
        &.ant-btn-text:hover,
        &.ant-btn-text:focus {
            color: $color-warning;
            border-color: transparent;
            background: transparent;
        }

        &.ant-btn-link,
        &.ant-btn-link:hover,
        &.ant-btn-link:focus {
            color: $color-warning;
            border-color: transparent;
            background: transparent;
            box-shadow: none;
        }
    }

    &-success {
        border-color: $bg-green !important;
        background: $bg-green !important;
        color: $bg-white !important;

        &:hover,
        &:active,
        &:focus {
            border-color: $bg-green !important;
            background: $bg-green !important;
            color: $bg-white !important;
        }

        &.ant-btn-text,
        &.ant-btn-text:hover,
        &.ant-btn-text:focus {
            color: $color-green;
            border-color: transparent;
            background: transparent;
        }

        &.ant-btn-link,
        &.ant-btn-link:hover,
        &.ant-btn-link:focus {
            color: $color-green;
            border-color: transparent;
            background: transparent;
            box-shadow: none;
        }
    }

    &-info {
        border-color: $color-blue !important;
        background: $color-blue !important;
        color: $color-white !important;

        &:hover,
        &:active,
        &:focus {
            border-color: $color-blue !important;
            background: $color-blue !important;
            color: $color-white !important;

        }

        &.ant-btn-text,
        &.ant-btn-text:hover,
        &.ant-btn-text:focus {
            color: $color-blue;
            border-color: transparent;
            background: transparent;
        }

        &.ant-btn-link,
        &.ant-btn-link:hover,
        &.ant-btn-link:focus {
            color: $color-blue;
            border-color: transparent;
            background: transparent;
            box-shadow: none;
        }
    }

    &-back {
        padding-left: 0;
        color: $text-green;

        &:hover,
        &:active,
        &:focus {
            color: $text-green !important;
        }

        span {
            margin-inline-start: 0 !important;
        }

        .left-arrow-icon {
            width: 16px !important;
            height: 16px !important;
            margin-right: 4px !important;
            padding-top: 2px;
        }
    }
    &-grey {
        background: $color-grey !important;
        color: $color-white !important;
        &:hover,
        &:active,
        &:focus {
            border-color: $color-grey !important;
            background: $color-grey !important;
            color: $color-white !important;

        }
    }
    &-pink {
        background: $color-pink !important;
        color: $color-white !important;
        border-color: $color-pink !important;
        &:hover,
        &:active,
        &:focus {
            border-color: $color-pink !important;
            background: $color-pink !important;
            color: $color-white !important;
        }
    }
    &-yellow {
        border-color: $color-yellow !important;
        background-color: $color-yellow !important;
        color: $bg-white !important;
        &:hover,
        &:active,
        &:focus {
            border-color: $color-yellow !important;
            background: $color-yellow !important;
            color: $color-white !important;
        }
    }

}

.btn-add {
    &__creation {
        margin-left: 0 !important;
        margin-right: auto !important;
    }

    &__creation-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 16px !important;
        gap: 8px;
        position: inherit;
        width: auto;
        height: auto;
        border: 1px solid $text-green !important;
        background: none;
        box-shadow: none !important;

        &:hover {
            background: none !important;
        }

        &:focus {
            background: none !important;
        }
    }

    &__creation-icon {
        color: $text-green;
        border: none;
    }

    &__creation-text {
        font-size: 16px;
        line-height: 24px;
        margin-left: 0 !important;
        color: $text-green;
    }
}

.ant-btn {
    &.ant-btn-icon-only {
        background-color: $bg-green;
        border-color: $bg-green;
        color: $color-white !important;
        &:hover,
        &:active,
        &:focus {
            background-color: $bg-green !important;
            border-color: $bg-green !important;
            color: $color-white !important;
        }
    }
}
.ant-btn-white-green-border {
    &.ant-btn-icon-only {
        background-color: $bg-white;
        border-color: $bg-green;
        color: $text-green !important;
        &:hover,
        &:active,
        &:focus {
            background-color: $bg-white !important;
            border-color: $bg-green !important;
            color: $text-green !important;
        }
    }
}
.ant-btn-white {
    &.ant-btn-icon-only {
        background-color: $bg-white;
        border-color: $border-dark;
        color: $color-dark !important;
        &:hover,
        &:active,
        &:focus {
            background-color: $bg-white !important;
            border-color: $border-dark !important;
            color: $color-dark !important;
        }
    }
}
